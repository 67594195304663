<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-alert
        type="info"
        color="lime darken-2"
        :value="true"
        class="mb-4"
        outline
      >
        <strong>
          Do take note :
          <br>
          •  Reward will be paid together with the <strong>monthly payout </strong> on the 15th.
          <v-spacer />
          • This menu serves as a reference. The total incentive shown is not final.
          <v-spacer />
          • Total activation might differ due to the status numbers at the end of the month.
        </strong>
      </v-alert>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2023-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </v-toolbar>
      <v-data-table
        :headers="activationHeaders"
        :items="activations"
        item-key="month"
        hide-actions
        :expand="expand"
      >
        <template #items="props">
          <tr @click="props.expanded = !props.expanded">
            <td class="text-xs-center">
              {{ props.item.campaign }}
            </td>
            <td class="text-xs-center">
              {{ props.item.totalAct }}
            </td>
            <td class="text-xs-center">
              {{ props.item.newDealer }}
            </td>
            <td class="text-xs-center">
              {{ getRm(props.item.incentive) }}
            </td>
            <td class="text-xs-center">
              {{ getRm(props.item.total) }}
            </td>
            <td class="text-xs-center">
              {{ props.item.payoutMethod }}
            </td>
          </tr>
        </template>
        <template #expand="">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="detailHeaders"
                :items="detail"
                class="elevation-1"
                item-key="name"
              >
                <template #items="thisProps">
                  <td class="text-xs-left">
                    {{ thisProps.item.name }}
                  </td>
                  <td class="text-xs-center">
                    {{ thisProps.item.value }}
                  </td>
                  <td
                    v-if="thisProps.item.active"
                    class="text-xs-center"
                  >
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </td>
                  <td
                    v-else
                    class="text-xs-center"
                  >
                    <v-icon color="error">
                      mdi-close
                    </v-icon>
                  </td>
                  <td
                    v-if="thisProps.item.qualified"
                    class="text-xs-center"
                  >
                    <v-icon
                      color="success"
                    >
                      mdi-check
                    </v-icon>
                  </td>
                  <td
                    v-else
                    class="text-xs-center"
                  >
                    <v-icon color="error">
                      mdi-close
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
      <br>
      <v-parallax
        dark
        height="70"
        src="/img/background.jpg"
      >
        <v-layout
          align-center
          column
          justify-center
        >
          <div class="headline font-weight-light">
            MEKANISMA TAKTIKAL KEMPEN
          </div>
        </v-layout>
      </v-parallax>
      <v-list
        class="transparent"
        two-line
      >
        <template v-for="(item, index) in descriptions">
          <v-subheader
            v-if="item.header"
            :key="item.header"
          >
            {{ item.header }}
          </v-subheader>

          <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          />
          <v-list-tile
            v-else
            :key="item.title"
            avatar
          >
            <v-list-tile-avatar>
              <v-btn
                absolute
                dark
                fab
                :ripple="false"
                color="black"
              >
                <v-avatar
                  size="50px"
                  :color="item.Color"
                >
                  <span class="white--text subheader">{{ item.Achievement }}</span>
                </v-avatar>
              </v-btn>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title v-html="item.title" />
              <v-list-tile-sub-title v-html="item.subtitle" />
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import { getRm } from '@/utils'

const pageTitle = 'Kempen Tactical'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "Panel" */ '@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        // const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local().setZone('Asia/Kuala_Lumpur').plus({ months: -3 })

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      title: pageTitle,
      items: [],
      selected: [],
      entrys: [],
      maxDate: '',
      loading: false,
      breadcrumbs: [
        {
          text: 'Campaigns', disabled: false, to: '/campaigns',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      getRm: getRm,
      activations: [],
      detail: [],
      monthOf: null,
      menu: false,
      detailHeaders: [
        {
          text: 'Msisdn',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Plan',
          align: 'center',
          sortable: false,
          value: 'value',
        },
        {
          text: 'Active',
          align: 'center',
          sortable: false,
          value: 'active',
        },
        {
          text: 'Qualified',
          align: 'center',
          sortable: true,
          value: 'qualified',
        },
      ],
      activationHeaders: [
        {
          text: 'Campaign Name',
          align: 'center',
          sortable: true,
          value: 'campaign',
        },
        {
          text: 'Total Activation',
          align: 'center',
          sortable: true,
          value: 'totalAct',
        },
        {
          text: 'New Dealer',
          align: 'center',
          sortable: true,
          value: 'newDealer',
        },
        {
          text: 'Incentive',
          align: 'center',
          sortable: true,
          value: 'incentive',
        },
        {
          text: 'Total Incentive',
          align: 'center',
          sortable: true,
          value: 'total',
        },
        {
          text: 'Payout Method',
          align: 'center',
          sortable: true,
          value: 'payoutMethod',
        },
      ],
      expand: false,
      descriptions: [
        { header: 'Mekanisma pengiraan incentive Tactical Campaign seperti yang dinyatakan berikut.' },
        {
          Color: '#800000',
          Achievement: 'RM3',
          title: 'Jumlah pengaktifan: 30 - 39',
          subtitle: '&mdash; Ganjaran (setiap pengaktifan)  RM3',
        },
        { divider: true, inset: true },
        {
          Color: 'orange darken-2',
          Achievement: 'RM5',
          title: 'Jumlah pengaktifan: 40 & above',
          subtitle: '&mdash; Ganjaran (setiap pengaktifan)  RM5',
        },
        { header: 'Syarat pengiraan pengaktifan: ' },
        { header: '• Semua pengaktifan mestilah mempunyai topup minima RM30 dalam bulan semasa ATAU minima B39 & ke atas ATAU pelan B10 dengan topup minima RM20.' },
        { header: '• Hanya pengaktifan personal sahaja akan dikira.' },
        { header: '• Pendaftaran perniagaan (Business Registration) tidak akan dikira dalam kempen ini.' },
        { header: '• Pelan Mysiswa yang diaktifkan semasa event universiti mestilah berserta dengan topup minima RM35.' },
        { header: '• Bagi pengaktifkan pelan Onefamily, hanya pendaftaran untuk Mainline sahaja akan dikira.' },
      ],
    }
  },
  watch: {
    monthOf: function (val) {
      this.getTacticalMekanisma(val)
      this.getTacticalUser(val)
    },
  },
  mounted: function () {
    this.getTacticalMekanisma()
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },

  methods: {
    getTacticalMekanisma: function (month) {
      const params = createGetParams({
        month: month,
      })

      this.$rest.get('getTacticalCampaign.php', params)
        .then((response) => {
          this.activations = response.data.item
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    getTacticalUser: function (month) {
      const params = createGetParams({
        month: month,
      })

      this.$rest.get('getTacticalCampaignUser.php', params)
        .then(function (response) {
          this.detail = response.data.items
          this.loading = false
        }.bind(this)).catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
